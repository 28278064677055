export default {
  get text() {
    return this.palette.black;
  },
  get background() {
    return this.palette.white;
  },
  get primary() {
    return this.palette.white;
  },
  palette: {
    blue: '#00294D',
    lightBlue: '#2C7AFC',
    black: '#000',
    white: '#FFFFFF',
    greyLight: '#D1D3D4',
    grey: '#757575',
    greyDark: '#222222',
    redDark: '#A51B20',
    yellow: '#ffc700',
    orange: '#F99D1C',
    orangeDark: '#FF7A00',
    green: '#00A458',
    greenLight: '#05DC78',
    teal: '#009FD6',
    moderate: '#64BF30',
    high: '#FEDD3A',
    extreme: '#F78100',
    catastrophic: '#AD0909',
  },
  preview: '#0070f3',
};
