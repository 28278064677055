import { rem } from 'polished';

export default {
  text: {
    small: rem(14),
    medium: rem(16),
    large: rem(20),
    xLarge: rem(24),
  },
};
