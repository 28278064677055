import { em } from 'polished';

export const baseUnits = {
  small: em(400),
  medium: em(768),
  large: em(1024),
  xlarge: em(1200),
};

export default {
  /** 400px */
  small: `@media screen and (min-width: ${baseUnits.small})`,
  /** 768px */
  medium: `@media screen and (min-width: ${baseUnits.medium})`,
  /** 1024px */
  large: `@media screen and (min-width: ${baseUnits.large})`,
  /** 1200px */
  xlarge: `@media screen and (min-width: ${baseUnits.xlarge})`,
  // This media query is used to handle the maximum screen size of mobile (400px)
  smallMax: `@media screen and (max-width: ${baseUnits.small})`,
};
