export default {
  ...[
    0, // default
    1, // hover/focus
    2, // active
    10, // modal-backdrop
    20, // modal
    30, // popover
    40, // tooltip
  ],
};
